import { useMemo, useState } from 'react'
import { filter } from 'lodash'

export const useSearch = (data) => {
  const [valueSearch, setValueSearch] = useState('')

  const dataSearch = useMemo(() => {
    if (valueSearch === '') {
      return data
    }
    return filter(data, e => e?.title.includes(valueSearch))
  }, [data, valueSearch])

  return {
    valueSearch,
    setValueSearch,
    dataSearch
  }
}
