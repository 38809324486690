import React from 'react'
import {
  Input as ChakraInput,
  FormControl,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Box, InputRightElement,
} from '@chakra-ui/react'
import { FormLabel } from '../FormLabel'

export const Input = React.forwardRef(
  (
    {
      name,
      error,
      isHideError,
      isInvalid,
      leftAddon,
      rightAddon,
      rightElement,
      label,
      mask,
      placeholder,
      value,
      tooltipLabel,
      size = 'md',
      textAlign = 'left',
      addonProps,
      ...props
    },
    ref
  ) => {
    return (
      <FormControl isInvalid={isInvalid}>
        {label && (
          <FormLabel name={name} tooltipLabel={tooltipLabel}>
            {label}
          </FormLabel>
        )}
        <InputGroup size={size}>
          {leftAddon && <InputLeftAddon children={leftAddon} />}
          <ChakraInput
            ref={ref}
            id={name}
            placeholder={placeholder}
            size={size}
            colorScheme="whiteAlpha"
            textAlign={textAlign}
            value={value || ''}
            {...props}
          />
          {rightAddon && (
            <InputRightAddon
              backgroundColor="gray.100"
              children={rightAddon}
              px="0"
              {...addonProps}
            />
          )}
          {rightElement && <InputRightElement>{rightElement}</InputRightElement>}
        </InputGroup>
        {props.description && (
          <Box fontSize="0.7rem" color="gray.400" mt="4px">
            {props.description}
          </Box>
        )}
        {error && isInvalid && !isHideError && (
          <Box fontSize="0.7rem" color="red.500" mt="4px">
            {error}
          </Box>
        )}
      </FormControl>
    )
  }
)
