import React from 'react'
import { Form } from 'components/Form'
import { BlockSection } from 'components/BlockSection'
import { Field, Formik } from 'formik'
import { isEmpty } from 'lodash'
import { InputForm } from 'components/InputForm'
import { TextAreaForm } from 'components/TextAreaForm'
import { Box, Button, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { FormLabel } from 'components/FormLabel'
import { CheckboxForm } from 'components/CheckboxForm'
import { useUpdateString } from './services/useUpdateString'
import { useInfo } from 'services/useInfo'
import { useAppsContext } from '../../../../../components/AppsContext'
import * as Yup from 'yup';
import { EditableForm } from '../../../../../../../components/EditableForm';

const formSchema = Yup.object().shape({
  key: Yup.string().required('Заполните поле'),
  appVersionNumber: Yup.string().required('Заполните поле'),
})

export const StringForm = (
  { data, activeVersionNumber, setActiveVersionNumber, onRefresh, appVersionNumber }
) => {
  const { updateString, isLoading } = useUpdateString()
  const { dataInfo } = useInfo()
  const { app } = useAppsContext()

  const handleForm = async (values) => {
    const dataRequest = {
      appId: app?.id,
      strings: [{
        ...values,
        ...!isEmpty(data) &&
        { appVersionNumber: (activeVersionNumber === 'key' || values.deleted) ? data?.appVersionNumber : appVersionNumber }
      }]
    }

    await updateString(dataRequest)
    onRefresh()
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={formSchema}
      initialValues={{
        key: data?.key ?? '',
        value: data.value,
        appVersionNumber: appVersionNumber,
        deleted: data?.deleted ?? false,
        comment: data?.comment ?? ''
      }}
      onSubmit={handleForm}
    >
      {(props) => {
        const isDelete = props.values.deleted
        return (
          <Form onSubmit={props.handleSubmit}>
            <BlockSection>
              <Field
                isHideError
                autoFocus={isEmpty(data)}
                name="key"
                label="Ключ"
                component={InputForm}
                isReadOnly={!isEmpty(data)}
                isDisabled={isLoading || isDelete}
              />
              <Field
                name="comment"
                placeholder="Комментарий"
                component={EditableForm}
                isDisabled={isLoading}
                fontSize="0.84rem"
              />
              {!isEmpty(data) && data?.appVersionNumber !== appVersionNumber && (
                <RadioGroup
                  value={props.values.deleted ? 'key' : activeVersionNumber}
                  onChange={setActiveVersionNumber}
                  isDisabled={isLoading || isDelete}
                >
                  <FormLabel>Версия</FormLabel>
                  <Stack spacing={5} direction="row">
                    <Radio colorScheme="teal" value="selected">
                      {appVersionNumber}
                    </Radio>
                    <Radio colorScheme="red" value="key">
                      {data?.appVersionNumber}
                    </Radio>
                  </Stack>
                </RadioGroup>
              )}
              {dataInfo?.languages?.map(i => (
                <Field
                  name={`value.${i}`}
                  label={i}
                  component={TextAreaForm}
                  isDisabled={isLoading || isDelete}
                  color="purple.300"
                  fontFamily="Menlo, monospace"
                  fontSize="0.88rem"
                  minH="180px"
                />
              ))}
              {!isEmpty(data) && (
                <Field name="deleted" component={CheckboxForm} label="Удалить" />
              )}
            </BlockSection>
            <Box pt="30px" pb="20px">
              <Button
                width="100%"
                type="submit"
                colorScheme={isDelete ? 'red' : 'teal'}
                isLoading={isLoading}
              >
                {isEmpty(data) ? 'Создать' : isDelete ? 'Удалить' : 'Обновить'}
              </Button>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}
