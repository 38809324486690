import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import { StringForm } from './components/StringForm/StringForm'
import { SegmentControl } from 'components/SegmentControl'
import { FileForm } from './components/FileForm/FileForm'
import { itemType } from 'constants/itemType'

export const Create = ({ data, isOpen, onClose, onRefresh, onOpen, appVersionNumber }) => {
  const isNew = useMemo(() => isEmpty(data), [data])
  const [activeVersionNumber, setActiveVersionNumber] = useState('selected')
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    setActiveTab(isNew ? 0 : data.type === itemType.string ? 0 : 1)
  }, [isNew, data])

  const handleClose = () => {
    setActiveVersionNumber('selected')
    onClose()
  }

  return (
    <>
      <Flex justifyContent="flex-end">
        <Button colorScheme="teal" onClick={onOpen}>
          Создать ключ
        </Button>
      </Flex>
      <Modal isOpen={isOpen} onClose={handleClose} size="lg" closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex alignItems="center">
              {isEmpty(data) ? 'Новый ключ' : 'Обновление ключа'}
              <Center
                width="fit-content"
                px="8px"
                height="20px"
                fontSize="0.9rem"
                backgroundColor="teal.200"
                fontWeight="700"
                transition="0.3s"
                borderRadius="full"
                color="gray.800"
                ml={3}
              >
                {appVersionNumber}
              </Center>
            </Flex>
          </ModalHeader>
          <ModalCloseButton mt={1} />
          <ModalBody>
            {isNew && (
              <SegmentControl
                width="100%"
                value={activeTab}
                onChange={(e) => setActiveTab(e)}
                mb={4}
                options={[
                  {
                    label: 'Строка',
                    value: 0,
                  },
                  {
                    label: 'Файл',
                    value: 1,
                  },
                ]}
              />
            )}
            {activeTab === 0 && (
              <StringForm
                data={data}
                appVersionNumber={appVersionNumber}
                activeVersionNumber={activeVersionNumber}
                setActiveVersionNumber={setActiveVersionNumber}
                onRefresh={onRefresh}
              />
            )}
            {activeTab === 1 && (
              <FileForm
                data={data}
                appVersionNumber={appVersionNumber}
                activeVersionNumber={activeVersionNumber}
                setActiveVersionNumber={setActiveVersionNumber}
                onRefresh={onRefresh}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
