import React, { useState, useEffect, useMemo } from 'react'
import { Toolbar } from 'components/Toolbar'
import { Box, Collapse, Fade, Grid, useBoolean } from '@chakra-ui/react'
import { useFetchVersions } from './services'
import { useSearch } from './mappings';
import { InputSearch } from 'components/InputSearch'
import { Create } from './components/Create/Create'
import { useFetchStrings } from './services'
import { SelectVersion } from './components/SelectVersion'
import { List } from './components/List'
import { VersionsList } from './components/VersionsList'
import { useAppsContext } from '../components/AppsContext'
import { isEmpty } from 'lodash'
import { useFetchFiles } from './services/useFetchFiles'
import { itemType } from 'constants/itemType'

export const Strings = () => {
  const refVersions = React.useRef()
  const { app } = useAppsContext()
  const { dataStrings, isLoadingFetchStrings, fetchStrings, clearStrings } = useFetchStrings(app.id)
  const { dataFiles, isLoadingFetchFiles, fetchFiles, clearFiles } = useFetchFiles(app.id)
  const dataList = useMemo(() => (
    [...dataStrings, ...dataFiles].map(i => ({
      ...i,
      type: i?.urls ? itemType.file : itemType.string
    }))
  ), [dataFiles, dataStrings])

  const { dataVersions, isSuccessFetchVersions, fetchVersions } = useFetchVersions(app.id)
  const [activeIndexVersion, setActiveIndexVersion] = useState(-1)
  const [isOpenVersions, setIsOpenVersions] = useBoolean()
  const [isOpenCreate, setIsOpenCreate] = useBoolean()
  const { valueSearch, setValueSearch, dataSearch } = useSearch(dataList)
  const [activeItem, setActiveItem] = useState({})
  const appVersionNumber = activeIndexVersion !== -1 && dataVersions[activeIndexVersion]?.appVersionNumber
  const isLoading = isLoadingFetchStrings || isLoadingFetchFiles

  const handleItemClick = (e) => {
    setActiveItem(e)
    setIsOpenVersions.off()
    setIsOpenCreate.on()
  }

  const handleStringsRefresh = async () => {
    setIsOpenCreate.off()
    setActiveItem({})
    await fetchStrings(appVersionNumber)
    await fetchFiles(appVersionNumber)
  }

  const handleVersionsRefresh = async () => {
    await fetchVersions(appVersionNumber)
  }

  const handleCloseCreate = () => {
    setIsOpenCreate.off()
    setActiveItem({})
  }

  useEffect(() => {
    setActiveIndexVersion(-1)
    clearStrings()
    clearFiles()
    fetchVersions()
  }, [app])

  useEffect(() => {
    if (dataVersions) {
      setActiveIndexVersion(0)
    }
  }, [dataVersions])

  useEffect(() => {
    if (isSuccessFetchVersions && isEmpty(dataVersions)) {
      setIsOpenVersions.on()
      return
    }
    setIsOpenVersions.off()
  }, [isSuccessFetchVersions, dataVersions])

  useEffect( () => {
    if (activeIndexVersion !== -1) {
      fetchStrings(appVersionNumber)
      fetchFiles(appVersionNumber)
    }
  }, [activeIndexVersion, dataVersions])

  return (
    <>
      <Toolbar>
        <Grid templateColumns="1fr 1fr 1fr" flex={1} height="100%" alignItems="center">
          <Box width="100%" pr="20px">
            <InputSearch
              placeholder="Поиск..."
              name="search"
              value={valueSearch}
              onChange={setValueSearch}
              isLoading={isLoading}
            />
          </Box>
          <SelectVersion
            ref={refVersions}
            data={dataVersions}
            index={activeIndexVersion}
            setIndex={setActiveIndexVersion}
            isOpen={isOpenVersions}
            onClickVersion={() => setIsOpenVersions.toggle()}
            onRefresh={handleVersionsRefresh}
          />
          <Fade in={!isOpenVersions && activeIndexVersion !== -1} unmountOnExit>
            <Create
              data={activeItem}
              isOpen={isOpenCreate}
              onOpen={() => setIsOpenCreate.on()}
              onClose={handleCloseCreate}
              appVersionNumber={appVersionNumber}
              onRefresh={handleStringsRefresh}
            />
          </Fade>
        </Grid>
      </Toolbar>
      <Box position="relative">
        <Collapse
          in={isOpenVersions}
        >
          <VersionsList
            ref={refVersions}
            data={dataVersions}
            onItemClick={e => setActiveIndexVersion(e)}
            activeIndex={activeIndexVersion}
          />
        </Collapse>
        <List
          data={dataSearch}
          isCollapse={isOpenVersions}
          onItemClick={handleItemClick}
        />
      </Box>
    </>
  )
}
