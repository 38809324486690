import React from 'react'
import { Select } from '../Select'
import _ from 'lodash'

export const SelectForm = React.forwardRef(
  ({ field, form, options, isHideError, isMulti = false, ...props }, ref) => {
    const handleChange = (option) => {
      if (option) {
        form.setFieldValue(field.name, isMulti ? option.map((item) => item.value) : option.value)
      } else {
        form.setFieldValue(field.name, '')
      }
    }

    const getValue = () => {
      if (options) {
        return isMulti
          ? options.filter((option) => field.value?.indexOf(option.value) >= 0)
          : options.find((option) => option.value === field.value)
      } else {
        return isMulti ? [] : ''
      }
    }

    return (
      <Select
        isForm
        name={field.name}
        value={getValue()}
        onChange={handleChange}
        options={options}
        isMulti={isMulti}
        isInvalid={_.get(form.errors, field.name) && _.get(form.touched, field.name)}
        error={_.get(form.errors, field.name)}
        {...props}
      />
    )
  }
)
