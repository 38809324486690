import useQuery from 'components/useQuery'
import { api } from 'api'
import { actions as apps } from 'store/apps'
import { useDispatch, useSelector } from 'react-redux'

export const useApps = () => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.apps)

  const {
    isLoading,
    request,
  } = useQuery(() => api.admin.fetchApps())

  const getApps = async (data) => new Promise( async (resolve, reject) => {
    try {
      const result = await request(data)
      dispatch(apps.update(result))
      resolve(result)
    } catch (e) {
      reject(e)
    }
  })

  const setActiveIndex = (index) => {
    dispatch(apps.setActiveIndex(index))
  }

  return {
    getApps,
    setActiveIndex,
    isLoadingApps: isLoading,
    dataApps: data
  }
}
