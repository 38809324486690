import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  list: [],
  activeIndex: 0
}

const {reducer, actions} = createSlice({
  initialState,
  name: 'apps',
  reducers: {
    reset: () => initialState,
    update: (state, action) => {
      state.list = action.payload?.list
    },
    setActiveIndex: (state, action) => {
      state.activeIndex = action.payload
    }
  }
});

export { actions, reducer }
export default reducer
