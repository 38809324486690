import React from 'react'
import { Stack } from '@chakra-ui/react'

export const BlockSection = ({ children, ...props }) => {
  return (
    <Stack spacing={4} {...props}>
      {children}
    </Stack>
  )
}
