import React from 'react'
import { extendTheme, ChakraProvider } from '@chakra-ui/react'

export const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        backgroundColor: 'gray.800'
      },
    },
  },
  colors: {
    yellowRus: {
      '50': '#FFF3E5',
      '100': '#FFDFB8',
      '200': '#FFCA8A',
      '300': '#FFB55C',
      '400': '#FFA02E',
      '500': '#FF8B00',
      '600': '#CC6F00',
      '700': '#995300',
      '800': '#663800',
      '900': '#331C00'
    }
  },
  config: {
    initialColorMode: 'dark'
  }
})

export const Theme = ({children}) => {
  return (
    <ChakraProvider theme={theme}>
      {children}
    </ChakraProvider>
  )
}
