import useQuery from 'components/useQuery'
import { api } from 'api'
import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'

export const useCreateUser = () => {
  const toast = useToast()
  const {
    isError,
    isLoading,
    isSuccess,
    request,
    error,
  } = useQuery((data) => api.admin.createUser(data))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: error.response?.data?.errorText,
        status: 'error',
      })
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Готово',
        description: 'Пользователь успешно создан',
        status: 'success'
      })
    }
  }, [isSuccess])

  return {
    createUser: request,
    isLoadingCreate: isLoading
  }
}
