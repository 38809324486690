import React from 'react'
import { Icon } from '@chakra-ui/icons'
import { IoIosApps } from 'react-icons/io'
import { Box, Center, Grid } from '@chakra-ui/react'
import { getDateTimeReadFormat } from 'utils/dateFormatting'

const Cell = ({ children, ...props }) => {
  return (
    <Box
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
      fontWeight="500"
      {...props}
    >
      {children}
    </Box>
  )
}

export const Item = ({ data, index, onClick }) => {
  return (
    <Grid
      role="group"
      px="26px"
      height="100%"
      templateColumns="40px minmax(200px, 1fr) minmax(300px, 1fr) minmax(300px, 1fr)"
      gap="20px"
      alignItems="center"
      cursor="pointer"
      _hover={{
        backgroundColor: 'whiteAlpha.100'
      }}
      onClick={() => onClick(data[index])}
    >
      <Center
        boxSize="38px"
        backgroundColor="teal.300"
        borderRadius="full"
      >
        <Icon as={IoIosApps} fontSize="1.3rem" color="gray.700"/>
      </Center>
      <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
        {data[index].title}
      </Box>
      <Cell>
        {data[index].key}
      </Cell>
      <Cell>
        {getDateTimeReadFormat(data[index].createdAt)}
      </Cell>
    </Grid>
  )
}
