import React from 'react'
import { Box } from '@chakra-ui/react';

export const Form = ({ onSubmit, children, ...props}) => {
  return (
    <form onSubmit={onSubmit}>
      <Box {...props}>
      {children}
      </Box>
    </form>
  )
}
