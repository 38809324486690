import useQuery from 'components/useQuery'
import { api } from 'api'
import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'

export const useLogout = () => {
  const toast = useToast()
  const {
    isError,
    isLoading,
    request,
    error,
  } = useQuery(() => api.admin.logout())

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: error.response?.data?.errorText,
        status: 'error',
      })
    }
  }, [isError])

  return { request, isLoading }
}
