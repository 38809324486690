import React from 'react'
import { useWindowHeight } from '../useWindowHeight'
import { Container } from '../Container'
import { Logo } from './Logo'
import { Box, Grid } from '@chakra-ui/react'
import { Profile } from './Profile'

export const AuthorizedWrapper = ({ children }) => {
  const windowHeight = useWindowHeight()
  return (
    <Container
      height={`${windowHeight}px`}
    >
      <Grid
        templateColumns="1fr 1fr"
        gap="20px"
        alignItems="center"
        justifyContent="center"
        py="22px"
        px="26px"
      >
        <Logo/>
        {/*<NavigationMenu/>*/}
        <Profile/>
      </Grid>
      <Box
        backgroundColor="#242d3c"
        flex={1}
        borderTopRightRadius="16px"
        borderTopLeftRadius="16px"
      >
        {children}
      </Box>
    </Container>
  )
}
