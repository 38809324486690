export const getFile = (file) => new Promise(async (resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onloadend = () => {
    const _result = {
      name: file.name,
      type: file.type,
      size: file.size,
      uri: reader.result,
    }
    resolve(_result)
    return _result
  }
  reader.onerror = (err) => {
    console.error(err)
    reject(err)
  }
})
