import React from 'react'
import { Flex } from '@chakra-ui/react'

export const Toolbar = ({ children, ...props }) => {
  return (
    <Flex
      px="26px"
      height="80px"
      alignItems="center"
      justifyContent="space-between"
      {...props}
    >
      {children}
    </Flex>
  )
}
