import React from 'react'
import {
  Box,
  Button, Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import { Form } from 'components/Form'
import { BlockSection } from 'components/BlockSection'
import { useUpdateUser } from './services/useUpdateUser'
import * as Yup from 'yup'
import { InputForm } from 'components/InputForm'
import { isEmpty } from 'lodash';
import { useCreateUser } from './services/useCreateUser'

const formSchema = Yup.object().shape({
  username: Yup.string().required('Заполните поле'),
  password: Yup.string().required('Заполните поле')
})

export const Create = (
  {
    data,
    isOpen,
    onClose,
    onRefresh,
    onOpen
  }) => {
  const { createUser, isLoadingCreate } = useCreateUser()
  const { updateUser, isLoadingUpdate } = useUpdateUser(data?.id)
  const isLoading = isLoadingUpdate || isLoadingCreate

  const handleForm = async (values) => {
    if (isEmpty(data)) {
      await createUser(values)
    } else {
      await updateUser(values)
    }
    onRefresh()
  }

  return (
    <>
      <Flex justifyContent="flex-end">
        <Button colorScheme="teal" onClick={onOpen}>Создать пользователя</Button>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isEmpty(data) ? 'Новый пользователь' : 'Обновление пользователя'}
          </ModalHeader>
          <ModalCloseButton mt={1} />
          <ModalBody>
            <Formik
              enableReinitialize
              validationSchema={formSchema}
              initialValues={{
                username: data?.username ?? '',
                password: data?.password ?? ''
              }}
              onSubmit={handleForm}
            >
              {(props) => {
                const isDelete = props.values.deleted
                return (
                  <Form onSubmit={props.handleSubmit}>
                    <BlockSection>
                      <Field
                        isHideError
                        autoFocus={isEmpty(data)}
                        name="username"
                        label="Логин"
                        component={InputForm}
                        isReadOnly={!isEmpty(data)}
                        isDisabled={isLoading}
                      />
                      <Field
                        type="password"
                        autoFocus={!isEmpty(data)}
                        name="password"
                        label="Пароль"
                        component={InputForm}
                        isDisabled={isLoading}
                      />
                    </BlockSection>
                    <Box pt="30px" pb="20px">
                      <Button
                        width="100%"
                        type="submit"
                        colorScheme={isDelete ? 'red' : 'teal'}
                        isLoading={isLoading}
                      >
                        {isEmpty(data) ? 'Создать' : isDelete ? 'Удалить' : 'Обновить'}
                      </Button>
                    </Box>
                  </Form>
                )
              }}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
