import React, { useState } from 'react'
import { Toolbar } from 'components/Toolbar'
import { Box, Grid, useBoolean } from '@chakra-ui/react'
import { InputSearch } from 'components/InputSearch'
import { useSearch } from './mappings'
import { useFetchUserList } from './services'
import { Create } from './components/Create'
import { AppList } from './components/AppList'
import { useApps } from 'services/useApps'

export const Apps = () => {
  const { dataAppList, isLoadingFetchAppList, fetchAppList } = useFetchUserList()
  const { valueSearch, setValueSearch, dataSearch } = useSearch(dataAppList)
  const [activeItem, setActiveItem] = useState({})
  const [isOpenCreate, setIsOpenCreate] = useBoolean()
  const { getApps } = useApps()

  const handleItemClick = (e) => {
    setActiveItem(e)
    setIsOpenCreate.on()
  }

  const handleRefresh = async () => {
    setIsOpenCreate.off()
    setActiveItem({})
    await fetchAppList()
    await getApps()
  }

  const handleCloseCreate = () => {
    setIsOpenCreate.off()
    setActiveItem({})
  }

  return (
    <>
      <Toolbar>
        <Grid templateColumns="1fr 1fr" flex={1} height="100%" alignItems="center">
          <Box width="100%" pr="20px">
            <InputSearch
              placeholder="Поиск..."
              name="search"
              value={valueSearch}
              onChange={setValueSearch}
              isLoading={isLoadingFetchAppList}
            />
          </Box>
          <Create
            data={activeItem}
            isOpen={isOpenCreate}
            onOpen={() => setIsOpenCreate.on()}
            onClose={handleCloseCreate}
            onRefresh={handleRefresh}
          />
        </Grid>
      </Toolbar>
      <AppList
        data={dataSearch}
        onItemClick={handleItemClick}
      />
    </>
  )
}
