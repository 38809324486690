import React from 'react'
import { Box } from '@chakra-ui/react'

export const CardWrapper = ({ children, ...props }) => {
  return (
    <Box
      width="480px"
      borderRadius="14px"
      backgroundColor="gray.700"
      //boxShadow="0px 0px 24px rgba(0, 0, 0, 0.05)"
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      overflow="hidden"
      {...props}
    >
      {children}
    </Box>
  )
}
