import React, { useEffect, useState } from 'react'
import { AuthorizedWrapper } from 'components/AuthorizedWrapper'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { PrivateRoute } from 'components/PrivateRoute'
import { Strings } from './Strings'
import { User } from './User'
import { RootLoading } from '../../components/RootLoading'
import { useApps } from '../../services/useApps'
import { AppsProvider } from './components/AppsContext'
import { Apps } from './Apps'

export const Authorized = () => {
  const [isLoading, setIsLoading] = useState(true)
  const match = useRouteMatch()
  const { getApps, dataApps } = useApps()

  useEffect(() => {
    const getData = () => {
      Promise.all([
        getApps()
      ]).then().finally(() => setIsLoading(false))
    }
    getData()
  }, [])

  return (
    <AuthorizedWrapper>
      {isLoading ? (
        <RootLoading label="Загружаю приложения..."/>
      ) : (
        <AppsProvider value={{ app: dataApps?.list[dataApps?.activeIndex] }}>
          <Switch>
            <PrivateRoute path={`${match.url}/strings`}>
              <Strings/>
            </PrivateRoute>
            <PrivateRoute path={`${match.url}/users`}>
              <User/>
            </PrivateRoute>
            <PrivateRoute path={`${match.url}/apps`}>
              <Apps/>
            </PrivateRoute>
            <Route path="/">
              <Redirect to={`${match.url}/strings`}/>
            </Route>
          </Switch>
        </AppsProvider>
      )}
    </AuthorizedWrapper>
  )
}

