import React from 'react'
import {
  Box,
  Button, Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import { Form } from 'components/Form'
import { BlockSection } from 'components/BlockSection'
import { useUpdateApps } from './services/useUpdateApps'
import * as Yup from 'yup'
import { InputForm } from 'components/InputForm'
import { isEmpty } from 'lodash';
import { useCreateApps } from './services/useCreateApps'

const formSchema = Yup.object().shape({
  title: Yup.string().required('Заполните поле'),
  key: Yup.string().required('Заполните поле')
})

export const Create = (
  {
    data,
    isOpen,
    onClose,
    onRefresh,
    onOpen
  }) => {
  const { createApps, isLoadingCreate } = useCreateApps()
  const { updateApps, isLoadingUpdate } = useUpdateApps(data?.id)
  const isLoading = isLoadingUpdate || isLoadingCreate

  const handleForm = async (values) => {
    if (isEmpty(data)) {
      await createApps(values)
    } else {
      await updateApps(values)
    }
    onRefresh()
  }

  return (
    <>
      <Flex justifyContent="flex-end">
        <Button colorScheme="teal" onClick={onOpen}>Создать приложение</Button>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isEmpty(data) ? 'Новое приложение' : 'Обновление приложения'}
          </ModalHeader>
          <ModalCloseButton mt={1} />
          <ModalBody>
            <Formik
              enableReinitialize
              validationSchema={formSchema}
              initialValues={{
                title: data?.title ?? '',
                key: data?.key ?? ''
              }}
              onSubmit={handleForm}
            >
              {(props) => {
                const isDelete = props.values.deleted
                return (
                  <Form onSubmit={props.handleSubmit}>
                    <BlockSection>
                      <Field
                        isHideError
                        autoFocus={!isEmpty(data)}
                        name="title"
                        label="Название"
                        component={InputForm}
                        isDisabled={isLoading}
                      />
                      <Field
                        name="key"
                        label="Ключ"
                        component={InputForm}
                        isDisabled={isLoading}
                      />
                    </BlockSection>
                    <Box pt="30px" pb="20px">
                      <Button
                        width="100%"
                        type="submit"
                        colorScheme={isDelete ? 'red' : 'teal'}
                        isLoading={isLoading}
                      >
                        {isEmpty(data) ? 'Создать' : isDelete ? 'Удалить' : 'Обновить'}
                      </Button>
                    </Box>
                  </Form>
                )
              }}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
