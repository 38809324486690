import useQuery from 'components/useQuery'
import { api } from 'api'
import { actions as info } from 'store/profile/info/store'
import { useDispatch, useSelector } from 'react-redux'

export const useInfo = () => {
  const dispatch = useDispatch()
  const { data } = useSelector(state => state.profile.info)

  const {
    isLoading,
    request,
  } = useQuery(() => api.admin.fetchInfo())

  const getInfo = async (data) => new Promise( async (resolve, reject) => {
    try {
      const result = await request(data)
      dispatch(info.update(result))
      resolve(result)
    } catch (e) {
      reject(e)
    }
  })

  return {
    getInfo,
    isLoadingInfo: isLoading,
    dataInfo: data
  }
}
