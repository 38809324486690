import { useMemo, useState } from 'react'
import { filter, orderBy } from 'lodash'

const findInObject = (obj, search) => {
  let result
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const str = obj[key].toLowerCase() ?? ''
      if (str.includes(search)) {
        result = true
      }
    }
  }
  return result
}

export const useSearch = (data) => {
  const [valueSearch, setValueSearch] = useState('')

  const dataSearch = useMemo(() => {
    if (valueSearch === '') {
      return data
    }
    return filter(data, e => {
      const key = e?.key.toLowerCase() ?? ''
      const _valueSearch = valueSearch.toLowerCase() ?? ''
      return key.includes(_valueSearch) || findInObject(e?.value, _valueSearch)
    })
  }, [data, valueSearch])

  const dataOrder = useMemo(() => {
    return orderBy(dataSearch, ['key'], 'asc')
  }, [dataSearch])

  return {
    valueSearch,
    setValueSearch,
    dataSearch: dataOrder
  }
}
