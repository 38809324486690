import React from 'react'
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Flex } from '@chakra-ui/react'
import { Route, useHistory } from 'react-router-dom'
import { useAuthentication } from '../../services/useAuthentication'

export const PrivateRoute = ({ path, hasAnyRoles, children, ...props }) => {
  const history = useHistory()
  const { isAuthenticated } = useAuthentication()
  return (
    <Route path={path} {...props}>
      {isAuthenticated ? children : (
        <Box px="26px">
          <Alert status="error" my="20px" borderRadius="10px" alignItems="flex-start" p="20px">
            <AlertIcon mt={1} />
            <Flex flexDirection="column" ml={1}>
              <AlertTitle mr={2}>Доступ запрещен</AlertTitle>
              <AlertDescription>
                Необходимо заново авторизоваться в системе
              </AlertDescription>
              <Box pt="10px">
                <Button size="sm" onClick={() => history.push('/')}>Войти</Button>
              </Box>
            </Flex>
          </Alert>
        </Box>
      )}
    </Route>
  )
}
