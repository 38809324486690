import React from 'react'
import { Flex } from '@chakra-ui/react'
import { useWindowHeight } from 'components/useWindowHeight'

export const UnauthorizedWrapper = ({ children }) => {
  const windowHeight = useWindowHeight()

  return (
    <Flex
      backgroundColor="gray.800"
      width="100%"
      overflowY="scroll"
      height={`${windowHeight}px`}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      {children}
    </Flex>
  )
}
