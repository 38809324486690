import { Editable, EditablePreview, EditableTextarea } from '@chakra-ui/react'

export const EditableForm = (
  { field, form, ...props }
) => {

  const handleChange = (e) => {
    form.setFieldValue(field.name, e)
  }

  return (
    <Editable
      value={field.value}
      onChange={handleChange}
      {...props}
    >
      <EditablePreview />
      <EditableTextarea />
    </Editable>
  )
}
