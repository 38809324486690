import { DropZone } from '../DropZone'
import _ from 'lodash'
import { getFile } from 'utils/getFile'

export const DropZoneForm = (
  {
    field,
    form,
    isCompress,
    isCropRequired,
    ...props
  }) => {
  const handleChange = async (e) => {
    const uploadFile = e[0]
    const _file = await getFile(uploadFile)
    const data = {
      file: _file.uri,
      fileName: _file.name,
      mimeType: _file.type
    }
    form.setFieldValue(field.name, data)
  }

  return (
    <DropZone
      //isLoading={isLoadingUpload}
      {...field}
      isInvalid={_.get(form.errors, field.name) && _.get(form.touched, field.name)}
      value={field.value}
      onChange={handleChange}
      {...props}
    />
  )
}
