import React from 'react'
import { Box, Center, Flex, Grid, Text } from '@chakra-ui/react'
import { FixedSizeList as ListWindow } from 'react-window'
import { Item } from './Item'
import { useWindowHeight } from 'components/useWindowHeight'
import { isEmpty } from 'lodash'
import { useInfo } from 'services/useInfo'

export const List = ({ data, onItemClick, isCollapse}) => {
  const windowHeight = useWindowHeight()
  const heightList = windowHeight - 202 - (isCollapse ? 300 : 0)
  const { dataInfo } = useInfo()

  const Row = ({index, key, style}) => (
    <div key={key} style={style} className="post">
      <Item
        data={data}
        index={index}
        onClick={onItemClick}
      />
    </div>
  )

  return (
    <Box>
      <Grid
        templateColumns={`40px minmax(200px, 1fr) repeat(${dataInfo?.languages?.length}, minmax(40px, 1fr)) 100px`}
        gap="20px"
        px="26px"
        fontSize="0.9rem"
        fontWeight="500"
        py="6px"
        backgroundColor="whiteAlpha.50"
      >
        <Box/>
        <Text>Ключ</Text>
        {dataInfo?.languages?.map(i => (
          <Text>{i}</Text>
        ))}
        <Flex justifyContent="flex-end">
          <Text>Версия</Text>
        </Flex>
      </Grid>
      {!isEmpty(data) ? (
        <ListWindow
          width="100%"
          height={heightList}
          itemCount={data?.length}
          itemSize={64}
        >
          {Row}
        </ListWindow>
      ) : (
        <Center height="300px">
          Нет ключей
        </Center>
      )}
    </Box>
  )
}
