import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Login } from './Login'
import { UnauthorizedWrapper } from 'components/UnauthorizedWrapper'

export const Unauthorized = () => {
  return (
    <UnauthorizedWrapper>
      <Switch>
        <Route path={`/`}>
          <Login />
        </Route>
      </Switch>
    </UnauthorizedWrapper>
  )
}
