import { Icon } from '@chakra-ui/icons'
import { Box, Center, Flex, Grid, HStack, IconButton, Text, useClipboard } from '@chakra-ui/react'
import { useInfo } from 'services/useInfo'
import { itemType } from 'constants/itemType'
import { HiDocumentText } from 'react-icons/hi'
import { MdTextFields } from 'react-icons/md'
import { FaCheckCircle, FaCopy } from 'react-icons/fa'

const Cell = ({ children, ...props }) => {
  return (
    <Box
      fontFamily="Menlo, monospace"
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
      fontSize="0.88rem"
      fontWeight="500"
      {...props}
    >
      {children}
    </Box>
  )
}

export const Item = ({ data, index, onClick }) => {
  const { dataInfo } = useInfo()
  const { hasCopied, onCopy } = useClipboard(data[index].key)

  const handleCopyClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    onCopy()
  }

  return (
    <Grid
      role="group"
      px="26px"
      height="100%"
      templateColumns={`40px minmax(200px, 1fr) repeat(${dataInfo?.languages?.length}, minmax(40px, 1fr)) 100px`}
      gap="20px"
      alignItems="center"
      cursor="pointer"
      _hover={{
        backgroundColor: 'whiteAlpha.100'
      }}
      onClick={() => onClick(data[index])}
    >
      <Center
        boxSize="38px"
        backgroundColor="teal.300"
        borderRadius="full"
      >
        <Icon as={data[index].type === itemType.string ? MdTextFields : HiDocumentText} fontSize="1.3rem" color="gray.700"/>
      </Center>
      <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" height="100%">
        <HStack spacing="10px" height="100%">
          <Text>{data[index].key}</Text>
          <IconButton
            isRound
            colorScheme="whiteAlpha"
            aria-label="Left"
            icon={hasCopied ? <FaCheckCircle color="white" fontSize="0.7rem" /> : <FaCopy color="white" fontSize="0.7rem" />}
            size="xs"
            opacity={0}
            transition="0.3s"
            _groupHover={{
              opacity: 1
            }}
            onClick={handleCopyClick}
          />
        </HStack>
      </Box>
      {dataInfo?.languages?.map(i => (
        <Cell color="purple.300">
          {data[index].type === itemType.string ? data[index].value[i] : (
            Object.keys(data[index].urls)[0] === i ? 'Да' : '-'
          )}
        </Cell>
      ))}
      <Flex justifyContent="flex-end">
        <Center
          width="fit-content"
          px="12px"
          height="20px"
          fontSize="0.9rem"
          backgroundColor="whiteAlpha.200"
          fontWeight="700"
          transition="0.3s"
          borderRadius="full"
        >
          {data[index].appVersionNumber}
        </Center>
      </Flex>
    </Grid>
  )
}
