import React from 'react'
import { Box, Center, Grid } from '@chakra-ui/react'

export const Item = ({ data, index, activeIndex, onClick }) => {
  const isActive = activeIndex === index

  return (
    <Grid
      role="group"
      pt="13px"
      pb="14px"
      px="26px"
      height="100%"
      templateColumns="46px 1fr"
      gap="16px"
      alignItems="center"
      cursor="pointer"
      _hover={{
        backgroundColor: 'whiteAlpha.100'
      }}
      onClick={() => onClick(index)}
    >
      <Center
        width="fit-content"
        px="12px"
        height="20px"
        fontSize="0.9rem"
        backgroundColor={isActive ? 'teal.200' : 'whiteAlpha.200'}
        color={isActive ? 'gray.800' : 'white'}
        fontWeight="700"
        transition="0.3s"
        borderRadius="full"
      >
        {data[index].appVersionNumber}
      </Center>
      <Box>
        {data[index].title}
      </Box>
    </Grid>
  )
}
