import React from 'react'
import { Box, Checkbox, FormControl } from '@chakra-ui/react'

export const CheckboxForm = React.forwardRef(({ field, form, label, labelProps, ...props }) => {
  const handleCheckbox = (e) => form.setFieldValue(field.name, e.target.checked)
  return (
    <FormControl>
      <Checkbox
        isChecked={field.value || false}
        onChange={handleCheckbox}
        {...props}
      >
        <Box {...labelProps}>{label}</Box>
      </Checkbox>

    </FormControl>
  )
})
