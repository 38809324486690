import { createContext, useContext } from 'react'

export const AppsContext = createContext({})

export const useAppsContext = () => {
  const context = useContext(AppsContext)
  if (!context) throw new Error('useAppsContext must be used within AppsProvider!')
  return context
}

export const AppsProvider = ({ value, children }) => {
  return <AppsContext.Provider value={value}>{children}</AppsContext.Provider>
}
