import React from 'react'
import { Flex } from '@chakra-ui/react'

export const Container = ({ children, ...props }) => {
  return (
    <Flex
      ml="auto"
      mr="auto"
      maxW="1800px"
      minW="1200px"
      pr="20px"
      pl="20px"
      flex={1}
      flexDirection="column"
      {...props}
    >
      {children}
    </Flex>
  )
}
