import axios from 'axios'
import { dataURIToBlob } from '../utils/dataURIToBlob';

const ENTITY = 'admin'

export const admin = Object.freeze({
  async fetchInfo(data) {
    const response = await axios.get(`/${ENTITY}/info`, data)
    return response.data
  },
  async login(data) {
    const response = await axios.post(`/${ENTITY}/login`, data)
    return response.data
  },
  async logout() {
    const response = await axios.get(`/${ENTITY}/logout`)
    return response.data
  },
  async getLogin() {
    const response = await axios.get(`/${ENTITY}/getLogin`)
    return response.data
  },
  async fetchStrings(appVersionNumber, appId) {
    const response = await axios.get(`/${ENTITY}/strings?appVersionNumber=${appVersionNumber}&appId=${appId}`)
    return response.data
  },
  async fetchFiles(appVersionNumber, appId) {
    const response = await axios.get(`/${ENTITY}/files?appVersionNumber=${appVersionNumber}&appId=${appId}`)
    return response.data
  },
  async updateStrings(data) {
    const response = await axios.post(`/${ENTITY}/strings`, data)
    return response.data
  },
  async fetchVersions(appId) {
    const response = await axios.get(`/${ENTITY}/${appId}/versions`)
    return response.data
  },
  async updateVersion(data) {
    const response = await axios.post(`/${ENTITY}/versions`, data)
    return response.data
  },
  async fetchUserList() {
    const response = await axios.get(`/${ENTITY}/user`)
    return response.data
  },
  async createUser(data) {
    const response = await axios.post(`/${ENTITY}/user`, data)
    return response.data
  },
  async updateUser(id, data) {
    const response = await axios.put(`/${ENTITY}/user/${id}`, data)
    return response.data
  },
  async fetchApps() {
    const response = await axios.get(`/${ENTITY}/apps`)
    return response.data
  },
  async createApps(data) {
    const response = await axios.post(`/${ENTITY}/apps`, data)
    return response.data
  },
  async updateApps(id, data) {
    const response = await axios.put(`/${ENTITY}/apps/${id}`, data)
    return response.data
  },
  async updateFile({ file, appId, fileName, key, appVersionNumber, lang, mimeType, comment, deleted }) {
    const data = new FormData()
    if (file) {
      const dataBlob = await dataURIToBlob(file)
      data.append('File', dataBlob)
    }
    appId && data.append('AppId', appId)
    key && data.append('Key', key)
    fileName && data.append('FileName', fileName)
    appVersionNumber && data.append('AppVersionNumber', appVersionNumber)
    lang && data.append('Lang', lang)
    mimeType && data.append('MimeType', mimeType)
    comment && data.append('Comment', comment)
    deleted && data.append('Deleted', deleted)

    const response = await axios.post(
      `/${ENTITY}/files`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }
    )
    return response.data
  }
})
