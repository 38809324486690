import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom'
import { Box } from '@chakra-ui/react'
import { Unauthorized } from 'pages/Unauthorized'
import { Authorized } from './Authorized'
import { useAuthentication } from 'services/useAuthentication'
import { RootLoading } from 'components/RootLoading'
import { useInfo } from 'services/useInfo'
import { Logout } from './Logout'

function App() {
  const [isLoading, setIsLoading] = useState(true)
  const { getProfile, isAuthenticated } = useAuthentication()
  const { getInfo } = useInfo()

  useEffect(() => {
    const getData = () => {
      Promise.all([
        getInfo(),
        getProfile()
      ]).then().finally(() => setIsLoading(false))
    }
    getData()
  }, [])

  return (
    <Box minW="1200px">
      {isLoading ? (
        <RootLoading/>
      ) : (
        <Switch>
          <Route path="/i">
            <Authorized/>
          </Route>
          <Route path={`/logout`} exact>
            <Logout/>
          </Route>
          <Route path="/">
            {isAuthenticated ? (
              <Redirect to={'/i'}/>
            ) : (
              <Unauthorized/>
            )}
          </Route>
        </Switch>
      )}
    </Box>
  );
}

export default App
