import axios from 'axios'
import { bindActionCreators } from '@reduxjs/toolkit'
import { store } from './store';
import { resetStore } from './store/reset'

const TIMEOUT = 1 * 60 * 3000;
axios.defaults.timeout = TIMEOUT;

const actions = bindActionCreators(
  { resetStore },store.dispatch);

const setupAxiosInterceptors = () => {
  const onResponseSuccess = response => response;
  const onResponseError = err => {
    const status = err.status || err.response?.status;
    if (status === 401) {
      actions.resetStore();
    }
    return Promise.reject(err);
  };
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

setupAxiosInterceptors();
