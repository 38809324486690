import React from 'react'
import ReactDOM from "react-dom"
import reportWebVitals from './reportWebVitals'
import { Theme } from 'components/Theme'
import { BrowserRouter as Router } from 'react-router-dom'
import { ScrollToTop } from 'components/ScrollToTop'
import App from './pages'
import { StoreProvider } from 'components/StoreProvider'
import './axios.config'

ReactDOM.render(
  <React.StrictMode>
    <Theme>
      <StoreProvider>
        <Router>
          <ScrollToTop />
          <App />
        </Router>
      </StoreProvider>
    </Theme>
  </React.StrictMode>,
  document.getElementById("root")
)

reportWebVitals()
