import React, { useMemo } from 'react'
import { Form } from 'components/Form'
import { BlockSection } from 'components/BlockSection'
import { Field, Formik } from 'formik'
import { isEmpty } from 'lodash'
import { InputForm } from 'components/InputForm'
import { Box, Button, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { FormLabel } from 'components/FormLabel'
import { CheckboxForm } from 'components/CheckboxForm'
import { useUpdateFile } from './services/useUpdateFile'
import { useAppsContext } from '../../../../../components/AppsContext'
import * as Yup from 'yup';
import { DropZoneForm } from 'components/DropZoneForm'
import { SelectForm } from 'components/SelectForm'
import { EditableForm } from 'components/EditableForm'
import { useInfo } from 'services/useInfo'

const formSchema = Yup.object().shape({
  key: Yup.string().required('Заполните поле'),
  appVersionNumber: Yup.string().required('Заполните поле'),
  fileData: Yup.object().test('okpd2', 'Заполните поле', e => !isEmpty(e?.file)),
})

export const FileForm = (
  { data, activeVersionNumber, setActiveVersionNumber, onRefresh, appVersionNumber }
) => {
  const isNew = useMemo(() => isEmpty(data), [data])
  const { updateFile, isLoading } = useUpdateFile()
  const { app } = useAppsContext()
  const { dataInfo } = useInfo()

  const handleForm = async (values) => {
    const dataRequest = {
      appId: app?.id,
      key: values?.key,
      appVersionNumber: (activeVersionNumber === 'key' || values.deleted) ? data?.appVersionNumber : appVersionNumber,
      deleted: values?.deleted,
      //fileName: values?.fileData?.fileName,
      mimeType: values?.fileData?.mimeType,
      lang: values.lang,
      file: values?.fileData?.file,
    }
    await updateFile(dataRequest)
    onRefresh()
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={formSchema}
      initialValues={{
        key: data?.key ?? '',
        appVersionNumber: appVersionNumber,
        deleted: data?.deleted ?? false,
        lang: data?.lang ?? '',
        comment: data?.comment ?? '',
        fileData: {
          file: null,
          url: !isEmpty(data?.urls) ? Object.values(data?.urls)[0] : '',
          fileName: null,
          mimeType: data?.mimeType ?? ''
        }
      }}
      onSubmit={handleForm}
    >
      {(props) => {
        const isDelete = props.values.deleted
        return (
          <Form onSubmit={props.handleSubmit}>
            <BlockSection>
              <Field
                isHideError
                autoFocus={isEmpty(data)}
                name="key"
                label="Ключ"
                component={InputForm}
                isReadOnly={!isEmpty(data)}
                isDisabled={isLoading || isDelete}
              />
              <Field
                name="comment"
                placeholder="Комментарий"
                component={EditableForm}
                isDisabled={isLoading || isDelete}
                fontSize="0.84rem"
              />
              {!isEmpty(data) && data?.appVersionNumber !== appVersionNumber && (
                <RadioGroup
                  value={props.values.deleted ? 'key' : activeVersionNumber}
                  onChange={setActiveVersionNumber}
                  isDisabled={isLoading || isDelete}
                >
                  <FormLabel>Версия</FormLabel>
                  <Stack spacing={5} direction="row">
                    <Radio colorScheme="teal" value="selected">
                      {appVersionNumber}
                    </Radio>
                    <Radio colorScheme="red" value="key">
                      {data?.appVersionNumber}
                    </Radio>
                  </Stack>
                </RadioGroup>
              )}
              {isNew && (
                <Field
                  name="lang"
                  label="Язык"
                  component={SelectForm}
                  placeholder="Выберите из списка"
                  isDisabled={isLoading}
                  options={dataInfo?.languages.map(i => ({
                    label: i,
                    value: i
                  }))}
                />
              )}
              <Field
                name="fileData"
                component={DropZoneForm}
                isDisabled={isLoading || isDelete}
              />
              {!isEmpty(data) && (
                <Field name="deleted" component={CheckboxForm} label="Удалить" />
              )}
            </BlockSection>
            <Box pt="30px" pb="20px">
              <Button
                width="100%"
                type="submit"
                colorScheme={isDelete ? 'red' : 'teal'}
                isLoading={isLoading}
              >
                {isEmpty(data) ? 'Создать' : isDelete ? 'Удалить' : 'Обновить'}
              </Button>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}
