import React from 'react'
import { Center, Heading, HStack, Box, Text } from '@chakra-ui/react'
import { Icon } from '@chakra-ui/icons'
import { SiSublimetext } from 'react-icons/si'
import { Link } from 'react-router-dom'
import { useInfo } from 'services/useInfo'

export const Logo = () => {
  const { dataInfo } = useInfo()
  return (
   <Link to={'/'}>
     <HStack spacing={3} alignItems="center">
       <Center
         boxSize="44px"
         backgroundColor="teal.200"
         borderRadius="full"
         bgGradient='linear(teal.200 0%, teal.300 50%)'
       >
         <Icon as={SiSublimetext} fontSize="1.3rem" color="white"/>
       </Center>
       <Box lineHeight={1.2}>
         <Heading fontSize="1.5rem">Строки</Heading>
         <Text fontSize="0.72rem" color="gray.400">{dataInfo?.adminPanelName}</Text>
       </Box>
     </HStack>
   </Link>
  )
}
