import { useDropzone } from 'react-dropzone'
import {
  Box,
  Button,
  Flex,
  Icon,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { BsCloudUpload } from 'react-icons/bs'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { isEmpty } from 'lodash'
import download from 'downloadjs'

export const DropZone = ({
  value,
  onChange,
  isInvalid,
  maxSize = 31457280,
  maxFiles = 1,
  multiple = false,
  height = '180px',
  width = '100%',
  isLoading,
  dropzoneOptions,
}) => {
  const toast = useToast()
  const borderColor = useColorModeValue('gray.200', 'gray.600')

  const { getRootProps, getInputProps } = useDropzone({
    //accept: accept,
    maxSize: maxSize,
    maxFiles: maxFiles,
    multiple: multiple,
    onDropAccepted: (e) => {
      onChange(e)
    },
    onDropRejected: (e) => {
      toast({
        description: `Изображение слишком большое`,
        variant: 'solid',
        status: 'error',
      })
    },
    ...dropzoneOptions,
  })

  const handleDownloadClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    download(value?.url)
  }

  return (
    <section className="dropzone-input">
      <Box
        width={width}
        height={height}
        borderWidth={1}
        borderRadius="var(--chakra-radii-md)"
        display="flex"
        alignItems="center"
        justifyContent="center"
        outline="none"
        cursor="pointer"
        transition="0.3s"
        borderColor={isInvalid ? 'red.500' : borderColor}
        overflow="hidden"
        borderStyle="dashed"
        {...getRootProps({ className: 'dropzone' })}
        {...(isInvalid && {
          boxShadow: '0 0 0 1px #e53e3e',
        })}
        _hover={{
          borderColor: 'whiteAlpha.500',
          boxShadow: 'none',
        }}
      >
        <input {...getInputProps()} />
        {!isEmpty(value?.file) || !isEmpty(value?.url) ? (
          <Flex flexDirection="column" maxW="300px" alignItems="center" textAlign="center">
            <Stack spacing="4px" alignItems="center">
              <Icon as={IoDocumentTextOutline} boxSize="30px" color="gray.300" mb={2} />
              {value?.url ? (
                <Button size="xs" onClick={handleDownloadClick}>
                  Скачать
                </Button>
              ) : (
                <Text fontSize="0.83rem" color="gray.400">
                  {value?.fileName}
                </Text>
                )}
            </Stack>
          </Flex>
        ) : (
          <Flex flexDirection="column" maxW="300px" alignItems="center" textAlign="center">
            <Icon as={BsCloudUpload} boxSize="30px" color="gray.300" mb={2} />
            <Text fontSize="0.83rem" color="gray.400">
              Перетащите сюда или щелкните, <br />
              чтобы выбрать
            </Text>
          </Flex>
        )}
        {isLoading && <Spinner color="blue.500" size="sm" marginRight="10px" />}
      </Box>
    </section>
  )
}
