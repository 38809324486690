import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Text,
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem, Flex, MenuDivider, MenuOptionGroup, MenuItemOption, Stack
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { useAuthentication } from 'services/useAuthentication'
import { useApps } from 'services/useApps'
import { isEmpty } from 'lodash'

export const Profile = () => {
  const history = useHistory()
  const { dataProfile } = useAuthentication()
  const { dataApps, setActiveIndex } = useApps()

  const handleApps = (e) => {
    setActiveIndex(e)
  }

  const handleLogout = () => {
    history.push('/logout')
  }

  return (
    <Flex justifyContent="flex-end">
      <Box
        display="flex"
        role="group"
        justifyContent="space-between"
        transition="0.3s"
      >
        <Flex
          flexGrow={1}
          textAlign="right"
          flexDirection="column"
          lineHeight="1.2"
          justifyContent="center"
        >
          <Text transition="0.3s" fontWeight="600">
            {dataProfile?.username}
          </Text>
          {!isEmpty(dataApps.list) && (
            <Text fontSize="0.84rem">
              app: {dataApps?.list[dataApps.activeIndex].title}
            </Text>
          )}
        </Flex>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label={'Menu'}
            marginLeft="14px"
            icon={<ChevronDownIcon/>}
            variant="outline"
            size="md"
            isRound
          />
          <MenuList zIndex={30} fontSize="1rem">
            {!isEmpty(dataApps) && (
              <>
                <MenuOptionGroup
                  value={dataApps?.activeIndex}
                  onChange={e => console.log(e)}
                  title="Активное приложение"
                  type="radio"
                >
                  {dataApps?.list?.map((item, index) => (
                    <MenuItemOption
                      key={item?.id}
                      value={index}
                      onClick={() => handleApps(index)}
                    >
                      {item?.title}
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
                <MenuDivider/>
              </>
            )}
            <MenuItem onClick={() => history.push('/i/apps')}>Приложения</MenuItem>
            <MenuItem onClick={() => history.push('/i/users')}>Пользователи</MenuItem>
            <MenuDivider/>
            <MenuItem onClick={handleLogout}>Выход</MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
};
